import axios from "axios";
import { endpoints } from "src/endpoints";

export const postUrlToGetContent = async (
  pdfURL: string,
  i: number,

  setConvertedCvCount: React.Dispatch<React.SetStateAction<number>>,
  setErrorList: React.Dispatch<React.SetStateAction<string[]>>
) => {
  try {
    const response = await axios.post(endpoints.CONVERT_CV, {
      pdfURL: pdfURL,
    });
    const data = response.data;
    setConvertedCvCount((prevCount) => prevCount + 1);
    return data;
  } catch (e) {
    console.log(e);
    setErrorList((prevErrorList) => [...prevErrorList, `Convert isteği ${i + 1}. CV'nin URL'inde hata verdi`]);
  }
};

export const postCvToGetContentShaped = async (pdfContent: string, i: number, setShapedCvCount: React.Dispatch<React.SetStateAction<number>>, setErrorList: React.Dispatch<React.SetStateAction<string[]>>, promptValues: string) => {
  try {
    const response = await axios.post(endpoints.SHAPE_CONTENT, {
      //model: "gpt-4-0613", // default model decided by backend
      content: encodeURIComponent(pdfContent),
    }, {
      headers: {
        'Accept': 'application/json'
      }
    });
    const data = response.data;
    setShapedCvCount((prevCount) => prevCount + 1);
    const evaluationResponse = await axios.post(endpoints.EVALUATE_CV, {
      extractedData: data,
      evaluationPrompt: encodeURIComponent(promptValues),
    });
    const aiRate = evaluationResponse.data.aiRate;
    data.aiRate = aiRate;
    return data;
  } catch (e) {
    console.log(e);
    setErrorList((prevErrorList) => [...prevErrorList, `OpenAI isteği ${i + 1}. CV'nin URL'inde hata verdi`]);
  }
};
