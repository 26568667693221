import { Stack, TextField, Typography } from "@mui/material";
import React from "react";

const CvOutput = ({ cvOutputValues }: { cvOutputValues: any[] }) => {
  return cvOutputValues.length > 0 ? (
    <TextField
      value={JSON.stringify(cvOutputValues)}
      label={"You can get the data by copying this text"}
      sx={{
        minWidth: "100%",
        bgcolor: "#ffffff",
        borderRadius: "8px",
        ".MuiTextField-root": {
          borderRadius: "8px",
        },
      }}
      variant="filled"
      multiline
      rows={7}
    >
      {JSON.stringify(cvOutputValues)}
    </TextField>
  ) : (
    <Stack
      sx={{
        minWidth: "100%",
        minHeight: "194px",
        bgcolor: "#ffffff",
        borderRadius: "8px",
        color: "black",
        alignItems: "flex-start",
        padding: "12px",
        fontSize: "1rem",
        mx: "12px !important",
      }}
    >
      {cvOutputValues.length === 0 && (
        <Stack spacing={1} alignItems={"flex-start"} sx={{ userSelect: "none" }}>
          <Typography variant="caption">1 - Lütfen başlamak için CV URL'lerini sol üstteki input alanına yapıştırın,</Typography>
          <Typography variant="caption">2 - Adayların değerlendirme kriterlerini sağ üstteki input alanına yazın ve "Start" butonuna tıklayın.</Typography>
          <Typography variant="caption">3 - İşlemi tamamlanan CV'ler ile ilgili bilgi burada güncellenerek gösterilecektir.</Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default CvOutput;
