import { useState } from "react";

import "./App.css";
import Greeting from "./components/Greeting";

import CvOutput from "./components/output/CvOutput";
import { Stack } from "@mui/material";
import Inputs from "./components/input/Inputs";

function App() {
  const [cvOutputValues, setCvOutputValues] = useState([] as any[]);

  return (
    <Stack className="App" spacing={2}>
      <Greeting />
      <Inputs cvOutputValues={cvOutputValues} setCvOutputValues={setCvOutputValues} />
      <CvOutput cvOutputValues={cvOutputValues} />
    </Stack>
  );
}

export default App;
