import { Alert, Stack, Typography } from "@mui/material";

import React, { useState } from "react";

import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { LoadingButton } from "@mui/lab";
import EvaluatePromptInput from "./EvaluatePromptInput";
import CvUrlInput from "./CvUrlInput";
import { postCvToGetContentShaped, postUrlToGetContent } from "../utils/services";

const Inputs = ({ setCvOutputValues, cvOutputValues }: { setCvOutputValues: React.Dispatch<React.SetStateAction<any[]>>; cvOutputValues: any[] }) => {
  const [cvInputValues, setCvInputValues] = useState("");
  const [promptValues, setPromptValues] = useState("");
  const [cvCounts, setCvCounts] = useState(0);
  const [shapedCvCount, setShapedCvCount] = useState(0);
  const [convertedCvCount, setConvertedCvCount] = useState(0);
  const [errorList, setErrorList] = useState([] as string[]);
  const [isLoading, setIsLoading] = useState(false);

  const clearResultsAndCountsOnStart = () => {
    setIsLoading(true);
    setErrorList([]);
    setConvertedCvCount(0);
    setShapedCvCount(0);
    setCvCounts(0);
    setCvOutputValues([] as any[]);
  };

  const handleUrlStart = async () => {
    clearResultsAndCountsOnStart();
    const regex = /(https?:\/\/[^\s]+)/g;
    if (cvInputValues.length > 0) {
      const urls = cvInputValues.match(regex) || [];
      setCvCounts(urls.length);

      for (let i = 0; i < urls.length; i++) {
        const url = urls[i];
        const pdfContent = await postUrlToGetContent(url, i, setConvertedCvCount, setErrorList);
        const shapedContent = await postCvToGetContentShaped(pdfContent, i, setShapedCvCount, setErrorList, promptValues);
        setCvOutputValues((prevOutput) => [...prevOutput, shapedContent]);
      }
      setIsLoading(false);
    }
  };

  const exportData = () => {
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(JSON.stringify(cvOutputValues))}`;
    const link = document.createElement("a");
    link.href = jsonString;
    link.download = "data.json";
    link.click();
  };

  return (
    <Stack
      spacing={2}
      sx={{
        width: "100%",
        alignItems: "center",
      }}
    >
      <Stack
        direction={"row"}
        sx={{
          justifyContent: "space-between",
          flexWrap: "wrap",
          width: "100%",
        }}
      >
        <CvUrlInput cvInputValues={cvInputValues} setCvInputValues={setCvInputValues} />
        <EvaluatePromptInput promptValues={promptValues} setPromptValues={setPromptValues} />
      </Stack>

      <Stack direction={"row"} spacing={2} sx={{ position: "relative", justifyContent: "center", alignItems: "center", minWidth: "100%", userSelect: "none" }}>
        <Typography variant="caption">
          {convertedCvCount} out of {cvCounts} converted
        </Typography>
        <LoadingButton
          loading={isLoading}
          sx={{
            padding: "8px 16px",
            fontWeight: "700",
          }}
          variant="contained"
          color="primary"
          onClick={handleUrlStart}
        >
          Start
        </LoadingButton>
        <Typography variant="caption">
          {shapedCvCount} out of {cvCounts} shaped
        </Typography>
        <DownloadForOfflineIcon
          color="primary"
          sx={{
            position: "absolute",
            right: "12px",
            width: "34px",
            height: "34px",
            cursor: "pointer",
            "&:hover": {
              transform: "scale(1.05)",
            },
            "&:active": {
              transform: "scale(1)",
            },
          }}
          onClick={exportData}
        />
      </Stack>
      {errorList.map((error, index) => (
        <Alert key={index} variant="filled" severity="error">
          {error}
        </Alert>
      ))}
    </Stack>
  );
};

export default Inputs;
