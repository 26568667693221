import { Stack } from "@mui/material";
import React from "react";

const Greeting = () => {
  return (
    <Stack className="greetings-container">
      <img src={"/apple-icon-180x180.png"} className="App-logo" alt="logo" />
      Beta Limited looking for new fellas
    </Stack>
  );
};

export default Greeting;
