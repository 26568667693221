import { TextField } from "@mui/material";

const EvaluatePromptInput = ({ promptValues, setPromptValues }: { promptValues: string; setPromptValues: React.Dispatch<React.SetStateAction<string>> }) => {
  return (
    <TextField
      value={promptValues}
      label={"Write the criteria for the evaluation here"}
      sx={{
        minWidth: "45%",
        bgcolor: "#ffffff",
        borderRadius: "8px",
        ".MuiTextField-root": {
          borderRadius: "8px",
        },
      }}
      variant="filled"
      onChange={(e) => setPromptValues(e.target.value)}
      multiline
      rows={7}
    />
  );
};

export default EvaluatePromptInput;
