import { TextField } from "@mui/material";

const CvUrlInput = ({ cvInputValues, setCvInputValues }: { cvInputValues: string; setCvInputValues: React.Dispatch<React.SetStateAction<string>> }) => {
  return (
    <TextField
      value={cvInputValues}
      label={"Paste applicant resumes here"}
      sx={{
        minWidth: "45%",
        bgcolor: "#ffffff",
        borderRadius: "8px",
        ".MuiTextField-root": {
          borderRadius: "8px",
        },
      }}
      variant="filled"
      onChange={(e) => setCvInputValues(e.target.value)}
      multiline
      rows={7}
    />
  );
};

export default CvUrlInput;
